#SIPChatbutton {
    position: fixed;
    bottom: -4px;
    right: 0;
    margin-bottom: 25px;
}

.SIPChat {
    max-width: 100%;
    margin: auto;
    word-wrap: break-word;
    margin-top: 40px;
}

    .SIPChat input:focus {
        outline: none !important;
        border: none !important;
    }

    .SIPChat p span {
        margin: 5px;
    }

        .SIPChat p span:first-child {
            margin-right: 0;
        }

    .SIPChat span span {
        margin: 5px;
    }

    .SIPChat .showLoading span span {
        margin: 0px;
    }

    .SIPChat span span:first-child {
        margin-right: 0;
    }

    .SIPChat .user_msg {
        text-align: right;
        margin-left: 40%;
        display: flex;
        flex-direction: row-reverse;
        color: white;
    }

.user_msg span {
    background-color: rgb(0, 120, 212);
    max-width: 100%;
    padding: 0px 10px;
    border-radius: 10px;
    box-shadow: 2px 2px 2px lightgray;
}

.SIPChat .server_msg {
    text-align: left;
    display: flex;
}

.server_msg span {
    background-color: transparent;
    max-width: 80%;
    padding: 0px 10px;
    border-radius: 10px;
    color: black;
    border: 1px solid black;
    box-shadow: 2px 2px 2px lightgray;
    display: inline-table
}

.copyIcon {
    display: none
}

.user_msg:hover .copyIcon, .server_msg:hover .copyIcon {
    display: inline-block;
}

.SIPChat .hide {
    visibility: hidden;
    display: none;
}

.SIPChat form {
    text-align: center;
    position: sticky;
    bottom: 0;
}

.SIPChat .input {
    width: 100%;
    height: 40px;
    border: none;
    padding: 10px;
    font-size: 1.2rem;
}

    .SIPChat .input:focus {
        outline: none;
    }

.SIPChat .loading {
    background-color: rgb(0, 120, 212);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6px;
    border-radius: 10px;
    max-width: 220px;
    color: white;
    margin-right: 10px
}

.SIPChat .showLoading {
    margin: auto;
    display: flex;
    justify-content: center
}

.SIPChat .faqSection {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 0px;
    border-radius: 5px;
    background-color: #e7f3f9;
    border: 1px solid #9ce4ff;
    padding-top: 6px;
    padding-bottom: 5px;
}

.faqQuestion {
    border: 1px solid #4dc4df;
    border-radius: 7px;
    box-shadow: 2px 2px 2px lightgray;
    margin: 3px;
    display: inline-table;
    height: 20px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    font-size: small;
    background-color: white
}

.curContext {
    border: 1px solid #4dc4df;
    border-radius: 5px;
    box-shadow: 2px 2px 2px lightgray;
    margin: 3px;
    display: inline-table;
    height: 22px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 5px;
    font-size: small;
    background-color: rgb(0, 120, 212);
    color: white;
    font-size: 15px
}

.SIPChat .contextSection {
    margin-top: 5px;
    margin-bottom: 5px;
    min-height: 0px;
    border-radius: 5px;
    padding-top: 6px;
    padding-bottom: 5px;
}


.faqQuestion:hover {
    background-color: #EFF3FF;
    color: #1543CD
}

.markdownStyle > * {
    white-space: normal;
}

code span {
    border: none !important;
    all: revert !important;
    padding: 0 !important;
    border-radius: unset !important;
    box-shadow: unset !important;
}

h3 code {
    color: inherit
}

.SIPChat .linenumber {
    display: none !important
}

.ResponseFooter {
    width: 100%;
    float: left;
    margin-top: 10px;
    margin-bottom: 0px;
    color: gray
}

    .ResponseFooter span {
        all: unset;
        margin: 0px !important
    }

    .ResponseFooter .divider {
        width: 1px;
        height: inherit;
        background: rgb(0,0,0,.1);
        display: flex;
        margin-left: 4px
    }


    .ResponseFooter .leftContent {
        float: left;
        width: 105px;
        padding: 0px 5px 0px 10px;
        border-radius: 10px;
        color: black;
        border: 1px solid rgba(0,0,0,0.1);
        display: flex
    }

    .ResponseFooter .rightContent {
        float: right;
        width: 48%;
        padding: 10px;
        text-align: right;
        min-width: 275px;
        font-size: 11px;
    }

.leftContent i {
    font-size: 18px;
    margin: 5px;
    cursor: pointer;
    color: gray
}

.leftContent {
    margin: 0
}

.feedbackContent {
    display: block;
    justify-content: space-between;
    align-items: center;
    margin: 0px 25px
}

.feedbackHeader {
    padding-top: 10px;
    margin: 0px;
    margin-bottom: 10px
}

.chatTab {
    position: relative;
    width: 150px;
    cursor: pointer;
    transition: left .4s ease-in-out;
    left: 0px
}

.chatTabHide {
    transition-delay: 500s;
    transition: left .4s ease-in-out;
    left: 97px;
}

.chatTab:hover {
    transition: left .4s ease-in-out;
    left: 0;
}
