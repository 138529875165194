
.active {
    background: lightgray;
}



  
.separator {
    display: flex;
    flex-direction: row;
  }
  .separator:before, .separator:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
  }
  .separator:before {
    margin-right: 10px
  }
  .separator:after {
    margin-left: 10px
  }


  @keyframes ellipsis {
    0% { content: ""; }
    25% { content: "."; }
    50% { content: ".."; }
    75% { content: "..."; }
    100% { content: ""; }
}

.typewriter:after {
    content: "";
    animation: ellipsis 2s infinite;
}